import React, { Component } from "react";
import Electrical from "../images/webImages/electrical.jpg";
import Robot from "../images/webImages/robot.jpg";
import Cable from "../images/webImages/cable.jpg";
import Developer from "../images/webImages/developer.jpg";
import Commit from "../images/webImages/commit.jpg";

export default class Commitment extends Component {
  render() {
    return (
      <div
        className="parentContainer"
        style={{
          backgroundImage: `url(${Commit})`,
          overflowY: "scroll",
        }}
      >
        <div className="EmptyDiv"></div>
        <div className="mainContainer">
          <div className="childContainer">
            <h1 className="h1">Message</h1>
            <h6 id="borderbelow" class="font-weight-bold mb-4">
              Our Commitment
            </h6>
          </div>
        </div>
        <div class="py-5 layer">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <p class="pb-5">
                  We have presented the statements from some of the employees
                  who work to support our data centers, speaking about the
                  strict attention paid to Intellize Technology services.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3 rounded">
                <img
                  class="ImgResp pb-3 rounded"
                  src={Electrical}
                  alt=""
                  title=""
                />
                <p class="text-center text-white font-weight-bold">
                  Message From Electrical Group Representative
                </p>
                <p class="pb-5">
                  One of the qualities that makes Intellize Technology different
                  among all, is its ability to respond to the distinct and
                  varied needs of its customers.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3 rounded">
                <img class="ImgResp pb-3 rounded" src={Robot} alt="" title="" />
                <p class="text-center text-white font-weight-bold">
                  Message From Maintenance Group Representative
                </p>
                <p class="pb-5">
                  Our customers highly bank on our data centers to ensure safe
                  custody of their important and significant information assets
                  , and our people are proud to support those centers.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3  rounded">
                <img class="ImgResp pb-3 rounded" src={Cable} alt="" title="" />
                <p class="text-center text-white font-weight-bold">
                  Message From Communications And Cabling Division
                </p>
                <p class="pb-5">
                  A thorough site-oriented, hands-on approach. There is a strict
                  standard we set for ourselves within Intellize Technology,
                  with its primary goal of zero downtime, every time.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3 rounded">
                <img
                  class="ImgResp pb-3 rounded"
                  src={Developer}
                  alt=""
                  title=""
                />
                <p class="text-center text-white font-weight-bold">
                  Message From A Developer
                </p>
                <p class="pb-5">
                  Since we have large number of overseas as well as domestic
                  clients, our goal is to provide best-in-class service while
                  studying examples of installations in other countries.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
