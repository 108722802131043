import React from "react";
import { Navbar, Nav, Container, NavItem } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Logo from "../images/webImages/logo.png";
import "../css/App.css";
import HOME from "../pages/home";
import AboutUs from "../pages/about";
import Strengths from "../pages/strengths";
import Service from "../pages/service";
import Commitment from "../pages/commitment";
import Contact from "../pages/contact";


function navBar() {
  return (
    <Router>
      <Navbar
        id="nav-bar-intellize"
        variant="dark"
        collapseOnSelect
        expand="lg"
        fixed="top"
        className="NavBarStyles"
      >
        <Container>
          <Navbar.Brand as={Link} to="/" href="/">
            <img
              src={Logo}
              height="50"
              className="d-inline-block align-top"
              alt="Intellize logo"
              onClick={() => (window.location.href = "/")}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavItem eventkey={1} href="/aboutUs">
                <Nav.Link id="nav-link" href="#aboutUS" as={Link} to="/aboutUs">
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={1}
                    className="underlineText"
                  >
                    <Box
                      p={1}
                      borderColor="text.primary"
                      fontWeight="fontWeightBold"
                    >
                      About Us
                    </Box>
                  </Box>
                </Nav.Link>
              </NavItem>
              <Nav.Link href="#ourStrength" as={Link} to="/strengths">
                <Box
                  display="flex"
                  className="underlineText"
                  justifyContent="flex-start"
                  p={1}
                >
                  <Box
                    p={1}
                    borderColor="text.primary"
                    fontWeight="fontWeightBold"
                  >
                    Our Strength
                  </Box>
                </Box>
              </Nav.Link>
              <Nav.Link href="#serviceDescription" as={Link} to="/service">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  className="underlineText"
                >
                  <Box
                    p={1}
                    borderColor="text.primary"
                    fontWeight="fontWeightBold"
                  >
                    Service Description
                  </Box>
                </Box>
              </Nav.Link>
              <Nav.Link href="#ourCommitment" as={Link} to="/commitment">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  p={1}
                  className="underlineText"
                >
                  <Box
                    p={1}
                    borderColor="text.primary"
                    fontWeight="fontWeightBold"
                  >
                    Our Commitment
                  </Box>
                </Box>
              </Nav.Link>
              <Nav.Link href="#contactUs" as={Link} to="/contact">
                <Box display="flex" justifyContent="flex-start" p={1}>
                  <Box
                    p={1}
                    borderColor="text.primary"
                    fontWeight="fontWeightBold"
                  >
                    Contact Us
                  </Box>
                </Box>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Switch>
        <Route exact path="/" component={HOME} />
        <Route exact path="/aboutUs" component={AboutUs} />
        <Route exact path="/strengths" component={Strengths} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/commitment" component={Commitment} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </Router>
  );
}

export default navBar;
