import React, { Component } from "react";
import Rack from "../images/webImages/rack.jpg";
import Rackcage from "../images/webImages/rackcage.png";
import RackFloor from "../images/webImages/rackFloor.png";
import RackTower from "../images/webImages/rackTower.png";
import DCenter from "../images/webImages/dCenter.jpg";

export default class Service extends Component {
  render() {
    return (
      <div
        className="parentContainer"
        style={{
          backgroundImage: `url(${DCenter})`,
          overflowY: "scroll",
        }}
      >
        <div className="EmptyDiv"></div>
        <div className="mainContainer">
          <div className="yourBlankSpaceDivClass"></div>

          <div className="childContainer">
            <h1 className="h1">Service</h1>
            <h6 id="borderbelow" class="font-weight-bold mb-4">
              Service Description
            </h6>
          </div>
        </div>
        <div className="about-copia py-5 layer">
          <div className="container main-container">
            <div className="row">
              <div className="col-sm-12 border-bottom text-white pb-2 mb-3">
                <h5>Basic Data Center Services</h5>
              </div>
              <div className="col-sm-12 mb-3">
                <p className="text-white">
                  The space in Intellize Technology data centres joins strength
                  and versatility and is accessible in three structures:
                  colocation, confined colocation, and housing. All are intended
                  to address the different needs of our clients.
                </p>
              </div>
              <div className="col-md-6 mb-4 d-flex">
                <div className="keypoints w-100 bgDark  p-3 rounded">
                  <h5>Rack colocation / Housing services</h5>
                  <img
                    className="ImgResp pb-3"
                    src={RackFloor}
                    alt=""
                    title=""
                  />
                  <p className="text-white">
                    Beginning from a single rack, the quantity of racks utilized
                    can be expanded as per business development. An assortment
                    of discretionary administrations is additionally accessible,
                    including performing business activities on the customer’s
                    behalf, and even migration backing to colocation/confined
                    colocation benefits on account of huge scale development.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 d-flex">
                <div className="keypoints w-100 bgDark  p-3 rounded">
                  <h5>Premium rack colocation services</h5>
                  <img className="ImgResp pb-3" src={Rack} alt="" title="" />
                  <p className="text-white">
                    A perfect service for the financial sector customers,
                    offering use as per changing business scale. This office
                    underpins high-thickness setups, and furthermore offers
                    remote checking of rack working conditions, bilingual
                    language backing, and intermediary IT observing work just as
                    the board for essential occurrence support. We can give a
                    wide assortment of administrations as per client
                    necessities.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 d-flex">
                <div className="keypoints w-100 bgDark  p-3 rounded">
                  <h5>Colocation services</h5>
                  <img
                    className="ImgResp pb-3"
                    src={RackTower}
                    alt=""
                    title=""
                  />
                  <p className="text-white">
                    Space is provisioned in server rooms with floor zones of 300
                    m2 or more. We design the structures that give the ideal
                    condition to the client's needs.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mb-4 d-flex">
                <div className="keypoints w-100 bgDark  p-3 rounded">
                  <h5>Caged colocation services</h5>
                  <img
                    className="ImgResp pb-3"
                    src={Rackcage}
                    alt=""
                    title=""
                  />
                  <p className="text-white">
                    These spaces can be utilized as high-security client
                    exclusive spaces. An enclosed space with enough space to
                    have 5 to 15 racks, these can be contracted for in units of
                    zones. Security levels for individual pens can be tweaked.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
