import React, { Component } from "react";
import HomeBanner from "../images/webImages/homeBanner.jpg";
import Trek from "../images/webImages/trek.jpg";
import DataCenter from "../images/webImages/dataCenter.jpg";
import IT from "../images/webImages/IT-perfessional.jpg";
import Security from "../images/webImages/security.jpg";
import ItEngineer from "../images/webImages/it-engineer.jpg";
import ServicesStaffing from "../images/webImages/services_staffing.jpg";
import Service from "../images/webImages/services.jpg";
import IStock from "../images/webImages/iStock.jpg";
import Maintenance from "../images/webImages/maintenance.jpg";

export default class Home extends Component {
  render() {
    return (
      <div className="homeMain">
        <section class="position-relative">
          <img className="homeImgResp" src={HomeBanner} alt="" title="" />
          <div className="over-lay-intellize"></div>
          <div className="intellize-banner-container">
            <div class="row">
              <div class="col-sm-12 ml-5">
                <h1 className="h1">intellize</h1>
                <h2 className="h2">your patner</h2>
                <h2 className="h2">for your growth</h2>
                <h3 className="h3">more than just technology</h3>
              </div>
            </div>
          </div>
        </section>
        <div class="py-5">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h1 className="h1">about</h1>
                <h6 id="borderbelow" class="font-weight-bold mb-5">
                  ABOUT US
                </h6>
                <p class="pb-5">
                INTELLIZE TECHNOLOGY was one of the "Fortune Global 500" companies
                for consecutive years, and ranked among top performing  in "Fortune
                Global 500"  list of Companies. It was also regarded  as "Asia's Most
                trusted Company. Intellize data centers are ISO 27001 certified, PCI-compliant
                and secured to meet FISC(*1) standards, ensuring the protection of your IT 
                infrastructure by exceptional levels of security at all times
                </p>
              </div>
            </div>
          </div>
          <img class="ImgResp pb-5" src={Trek} alt="" title="" />
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h1 className="h1">strengths</h1>
                <h6 id="borderbelow" class="font-weight-bold mb-5">
                  OUR STRENGTHS
                </h6>
                <p class="pb-5">
                  Strengths of the world-leading data centers provided by
                  INTELLIZE TECHNOLOGY.{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={DataCenter} alt="" title="" />
                <p class="pb-5">
                  TIER III+ TIER IV Intellize data centers secure 99.999% uptime
                  with N+2, N+1 UPS and N+1 generator power. Everything we do at
                  TIS is built on the most advanced, robust infrastructure
                  ensuring high availability, securing operations of customers’
                  critical systems. Our facilities are located in strong, secure
                  buildings that can withstand almost any type of natural
                  disaster. We provide services to a world-leading standard,
                  including reliable and flexible power supplies, ample air
                  conditioning, spaces with expansion potential, and a selection
                  of solutions for networks, security, and operations
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={IT} alt="" title="" />
                <p class="pb-5">
                  We have leading experts in each field of the wide-ranging
                  operations associated with data centers that can answer our
                  customers’ diverse requests. We are also able to provide this
                  high quality service for overseas customers with Multi-lingual
                  services.
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={Security} alt="" title="" />
                <p class="pb-5">
                  Comprising of 24-hour, 365-day observing of all buildings by
                  guards and focal checking frameworks just as various security
                  systems, we keep up a protected environment with the large
                  amount of innovation and ability in terms of both software and
                  hardware. we have obtained certification for the Information
                  Security Management System (ISMS) Conformity Assessment System
                  (not pertinent to certain destinations), just as Privacy Mark
                  capabilities.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={ItEngineer} alt="" title="" />
                <p class="pb-5">
                  Intellize technology has ultra-low latency connection
                  environments within its data centers. Various domestic and
                  overseas industries have formed their own communities, where
                  this connectivity aids the growth of the businesses of our
                  customers. Intellize technology is associated with different
                  bearers and on-location IX via steady and repetitive stage.
                  Our system configuration enables us to give consistent
                  Internet network notwithstanding during part glitches or
                  during support of the host ISP and uses in-house hardware for
                  all segments, including all premises wiring. Our condition
                  empowers brisk reaction times even in the most pessimistic
                  scenario situations and can effectively support the
                  zero-personal time activity.{" "}
                </p>
              </div>
            </div>
          </div>
          <img class="ImgResp pb-5" src={ServicesStaffing} alt="" title="" />
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h1 className="h1">service</h1>
                <h6 id="borderbelow" class="font-weight-bold mb-5">
                  Service Descriptions
                </h6>
                <p class="pb-3">
                  The space in Intellize technology data centres joins strength
                  and versatility and is accessible in three structures:
                  colocation, confined colocation, and housing. All are intended
                  to address the different needs of our clients.{" "}
                </p>
                <p>
                  This service provides closed connectivity to cloud services
                  including AWS, Google Cloud Platform™, IBM Cloud, and
                  Microsoft Azure. This service allows customers to construct
                  highly reliable hybrid cloud utilization environment by
                  offering private connections from customer's on-premise
                  systems to the cloud services.
                </p>
              </div>
            </div>
          </div>
          <img class="ImgResp mb-5" src={Service} alt="" title="" />
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h1 className="h1">message</h1>
                <h6 id="borderbelow" class="font-weight-bold mb-5">
                  Our Comittment
                </h6>
                <p class="pb-5">
                  We have presented the statements from some of the employees
                  who work to support our data centers, speaking about the
                  strict attention paid to Intellize technology.{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={IStock} alt="" title="" />
                <p class="pb-5 text-center">
                  Message From Electrical Group Representative{" "}
                </p>
                <p class="pb-5">
                  One of the qualities that makes Intellize Technology different
                  among all, is its ability to respond to the distinct and
                  varied needs of its customers.{" "}
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <img class="ImgResp mb-3" src={Maintenance} alt="" title="" />
                <p class="pb-5 text-center">
                  Message From Maintenance Group Representative{" "}
                </p>
                <p class="pb-5">
                  Our customers highly bank on our data centers to ensure safe
                  custody of their important and significant information assets
                  , and our people are proud to support those centers.{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h1 className="h1">contact</h1>
                <h6 id="borderbelow" class="font-weight-bold mb-5">
                  Contact Us
                </h6>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding">
                <h4 class="font-weight-bold">INTELLIZE TECHNOLOGY</h4>
                <p class="font-weight-bold">
                  # 805, BB2 Mazaya Business Avenue, Jumeirah Lakes Towers, Dubai
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <p class="text-center text-white">Contact us by telephone:</p>
                <div class="p-5 border border-light text-center">
                  <a
                    style={{ fontSize: "20px" }}
                    class="text-white text-center"
                    href="tel:+0421340352"
                  >
                    <small class="font-weight-bold">(Tharun) +0421340352</small>
                  </a>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <p class="text-center text-white">Contact us by e-mail:</p>
                <div class="p-5 border border-light text-center">
                  <a
                    style={{ fontSize: "20px" }}
                    class="text-white text-center"
                    href="mailto:Tharun@intellizetechnology.com"
                  >
                    <small class="font-weight-bold">
                      Tharun@intellizetechnology.com
                    </small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
