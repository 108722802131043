import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";

const themeDark = createMuiTheme({
  palette: {
    background: {
      default: "#003b44",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  typography: {
    fontSize: 18,
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={themeDark}>
    <CssBaseline />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
