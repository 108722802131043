import React from "react";
import "../../css/App.css";

const footer = () => (
  <div>
    <div className="FooterPage">
      <span style={{ fontSize: 16 }}>
        {" "}
        All rights reserved. INTELLIZE TECHNOLOGY LLC
      </span>
    </div>
  </div>
);
export default footer;
