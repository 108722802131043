import React, { Component } from "react";
import Strength from "../images/webImages/strength.jpg";
import Unity from "../images/webImages/unity.jpg";
import Servicekey from "../images/webImages/servicekey.jpg";
import Supportkey from "../images/webImages/supportkey.jpg";
import Handshake from "../images/webImages/handshake.jpg";

export default class Strengths extends Component {
  render() {
    return (
      <div
        className="parentContainer"
        style={{
          backgroundImage: `url(${Strength})`,
          overflowY: "scroll",
        }}
      >
        <div className="EmptyDiv"></div>
        <div className="mainContainer">
          <div className="yourBlankSpaceDivclassName"></div>
          <div className="childContainer">
            <h1 className="h1">Strengths</h1>
            <h6 id="borderbelow" className="font-weight-bold mb-4">
              Our Strengths
            </h6>
          </div>
        </div>
        <section className="about-copia  py-5 layer">
          <div className="container main-container">
            <div className="row">
              <div className="col-sm-12 border-bottom text-white pb-2 mb-5 mt-3">
                <h5>
                  Facilities That Provides Significant Attention To
                  Mission-Critical Situations.
                </h5>
              </div>
              <div className="col-md-6 mb-3">
                <img class="ImgResp" src={Unity} alt="" title="" />
              </div>
              <div className="col-md-6">
                <h5 className="text-white">
                  Facility systems with seismically-isolated structures that are
                  safe and secure
                </h5>
                <p className="text-white">
                  Intellize Technology data centers guarantee profoundly
                  trustworthy power supply with multiplexed power supply
                  frameworks and dependable excess offices. They are completely
                  prepared for the impacts of a wide scope of environmental
                  catastrophes with approaching force that utilizations
                  underground links, and power sources with UPS (uninterruptible
                  power supplies). For worst-case scenario situations, they are
                  fitted with high-speed recovery systems that are resistant to
                  vibration, utilizing EG's (crisis generators) which are
                  required as a pre-imperative for server farm choice by
                  numerous clients.{" "}
                </p>
              </div>
              <div className="col-sm-12 keypoints my-4">
                <h5>Key Points</h5>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-bolt fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  Highly-reliable supply of electricity
                </h6>
                <p className="text-left text-white">
                  Intellize Technology Centers guarantee a profoundly
                  trustworthy power supply with multiplexed power supply
                  frameworks and dependable excess offices. Approaching force is
                  gotten through underground links and various courses, and are
                  not expose to hazard factors, for example, a lightning strike
                  or comparable. Indeed, even in worst case scenario situations,
                  a relentless power supply is accessible through on location
                  EG's (crisis generators).{" "}
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-building fa-5x mb-4"></i>
                <h6 className="text-white text-center">Robust buildings</h6>
                <p className="text-left text-white">
                  Intellize Technology data centers have achieved the most
                  noteworthy PML positioning. With this world-driving quality of
                  center structures, we can securely bolster the persistent
                  activity of the client's matter of fact and imperative data
                  resources.{" "}
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-lock fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  A world-leading level of security
                </h6>
                <p className="text-left text-white">
                  Comprising of 24-hour, 365-day observing of all buildings by
                  guards and focal checking frameworks just as various security
                  systems, we keep up a protected environment with the large
                  amount of innovation and ability in terms of both software and
                  hardware. we have obtained certification for the Information
                  Security Management System (ISMS) Conformity Assessment System
                  (not pertinent to certain destinations), just as Privacy Mark
                  capabilities.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="about-copia  py-5 layer">
          <div className="container main-container">
            <div className="row">
              <div className="col-sm-12 border-bottom text-white pb-2 mb-5">
                <h5>
                  Distinct Service Offerings, Backed Up With Experience,
                  Knowledge and Intelligence.
                </h5>
              </div>
              <div className="col-md-6 mb-3">
                <img class="ImgResp" src={Servicekey} alt="" title="" />
              </div>
              <div className="col-md-6">
                <h5 className="text-white">
                  Operational know-how and the strength of an independent
                  business
                </h5>
                <p className="text-white">
                  Intellize Technology outstanding record of achieving the
                  necessities of clients from an assortment of business fields
                  that have been aggregated during activity and the board of a
                  wide scope of data center infrastructures. On the off chance
                  that you are uncertain about the administration and activity
                  of the server framework inside your own organization, on the
                  off chance that you believe you are deficient with regards to
                  the specialized staff required, if you have quality-related
                  worries about issues, for example, security, systems, or air
                  conditioning management, or if you just wish to manage and
                  operate your server infrastructure more effectively so you can
                  focus on your core business.
                </p>
              </div>
              <div className="col-sm-12 keypoints my-4">
                <h5>Key Points</h5>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-globe fa-5x mb-4"></i>
                <h6 className="text-white text-center">Networks</h6>
                <p className="text-left text-white">
                  Intellize Technology is associated with different bearers and
                  on-location IX via steady and repetitive stage. Our system
                  configuration enables us to give consistent Internet network
                  notwithstanding during part glitches or during support of the
                  host ISP and uses in-house hardware for all segments,
                  including all premises wiring. Our condition empowers brisk
                  reaction times even in the most pessimistic scenario
                  situations and can effectively support the zero-personal time
                  activity.{" "}
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-cog fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  In depth knowledge and secure operation
                </h6>
                <p className="text-left text-white">
                  Intellize Technology data center specialists can offer help
                  for a wide assortment of tasks, from monitoring and the
                  management of servers and other IT gadgets, through checking
                  work being completed in our server farms for the client's
                  benefit, and up to remote hands activities. <br />
                  For further details, please contact us to find out how we can
                  provide you with best of market solutions.
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-users fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  The strengths of an independent business and of our people
                </h6>
                <p className="text-left text-white">
                  Our people are the primary resource that supports our data
                  centers. In what capacity should surprising occasions inside a
                  server farm be taken care of? The capacity to deal with crisis
                  reaction includes working up genuine hands-on encounters, and
                  to then recollect and expand upon those abilities learned.
                  Intellize Technology is specialist from architecture,
                  electrical engineering, networking, and different orders gain,
                  aggregate, and completely apply their insight every day to
                  guarantee persistent tasks went for zero personal time. This
                  is the wellspring of our quality as a free business.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about-copia layer  py-5">
          <div className="container main-container">
            <div className="row">
              <div className="col-sm-12 border-bottom text-white pb-2 mb-5">
                <h5>
                  Best in className assistance For Business Development Of Our
                  Customers.
                </h5>
              </div>
              <div className="col-md-6 mb-3">
                <img class="ImgResp" src={Supportkey} alt="" title="" />
              </div>
              <div className="col-md-6">
                <h5 className="text-white">
                  Achieving business growth for customers with "DC in DC"
                </h5>
                <p className="text-white">
                  Clients can trust on Intellize Technology with the all-out
                  foundation of Data centers that they have to work. We can do
                  office activity (patrols, equipment monitoring and vendor
                  calls, monitoring of internal environments including power
                  supplies, temperature, humidity, equipment
                  inspections/maintenance), security activity (for example get
                  to the board, key/ID card the board, log the executives), and
                  in-room tasks (for example gadget establishment, movement,
                  extra segment establishment, and segment substitution, just as
                  accompanying merchants, and so on, intermediary Help Desk/Call
                  Center activity, cabling, capacity of assets, and so forth.).
                  We additionally can plan to report as per your needs and can
                  give support for government-related strategies and reviews. We
                  can bolster the whole activity of your data center.{" "}
                </p>
              </div>
              <div className="col-sm-12 keypoints my-4">
                <h5>Key Points</h5>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-cubes fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  Adaptable Versatility{" "}
                </h6>
                <p className="text-left text-white">
                  Business conditions are liable to significant changes that can
                  cause difficulties in making capacity planning predictions,
                  thus adaptable versatility for data centers is fundamental.
                  With its Chuo Center, flaunting an all-out floor territory
                  that is among the biggest offices on the planet, Intellize
                  Technology server farms take into consideration scope of
                  profoundly adaptable movement and development choices for your
                  business.
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-database fa-5x mb-4"></i>
                <h6 className="text-white text-center">Dc Inside Dc </h6>
                <p className="text-left text-white">
                  Clients can trust Intellize Technology with their data center
                  framework and operations. We will take control over the
                  responsibility regarding the data center that they oversee.
                  While we offer office operations, security tasks, and in-room
                  tasks, we additionally offer help as per client needs that
                  incorporate an arrangement of reports, as help to address
                  government-related strategies and reviews.
                </p>
              </div>
              <div className="col-md-4 keypoints text-center">
                <i className="fa fa-exclamation-triangle fa-5x mb-4"></i>
                <h6 className="text-white text-center">
                  Excess Setup, And Expectation Of Dangers{" "}
                </h6>
                <p className="text-left text-white">
                  All equipment in Intellize Technology data center, including
                  the power supply frameworks and systems, are set up with the
                  expectation of giving completely repetitive courses (2N). This
                  enables us to pursue the objective of zero down time by
                  putting the most elevated need on the proceeded with the
                  activity of client resources inside our focuses. This is
                  conveyed by the reception of the adaptable hardware plan that
                  considers the requirement for required support windows and
                  substitution of enormous scale gear and gadgets. Seen as a
                  foundation continuing fundamental frameworks that can't endure
                  ANY system downtime, these plans bolster the mission-basic
                  organizations of our clients.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about-copia layer py-5">
          <div className="container main-container">
            <div className="row">
              <div className="col-sm-12 border-bottom text-white pb-2 mb-5">
                <h5>Carrier-neutral data centers</h5>
              </div>
              <div className="col-md-6 mb-3">
                <img class="ImgResp" src={Handshake} alt="" title="" />
              </div>
              <div className="col-md-6">
                <h5 className="text-white">Carrier-neutral, vendor-neutral</h5>
                <p className="text-white">
                  Intellize Technology data centers have no stipulation on the
                  tenancy of telecome carriers. We provide an extensive
                  environment, including the lead-in communications cables where
                  over 20 telecom providers, including foreign carriers, can be
                  accessed freely. We have also achieved high accessibility via
                  a cable network that enables us to function as an
                  international communications hub.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
