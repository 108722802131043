import React from "react";
import NavBar from "../components/navBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Footer from "../components/footer";
import "../css/App.css";

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className="MainCountainer">
        <NavBar />
        </div>
       <Footer />
    </React.Fragment>
  );
}

export default App;
