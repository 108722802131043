import React, { Component } from "react";
import About from "../images/webImages/about.jpg";

export default class AboutUs extends Component {
  render() {
    return (
      <div
        className="parentContainer"
        style={{
          backgroundImage: `url(${About})`,
          overflowY: "scroll",
        }}
      >
        <div className="EmptyDiv"></div>
        <div className="mainContainer">
          <div className="childContainer">
            <h1 className="h1">about</h1>
            <h6 id="borderbelow" class="font-weight-bold mb-4">
              About Us
            </h6>
          </div>
        </div>
        <section class=" py-5 layer">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 text-center text-white heding mt-5">
                <p class="pb-5">
                  The Company was one of the "Fortune Global 500" companies for
                  consecutive years, and ranked among top performing  in "Fortune 
                  Global 500"  list of Companies. It was also regarded  as "Asia's
                   Most trusted Company.Under our company’s ideology of “ providing
                  information and knowledge to support the foundation of the community”,
                  all the employees of Intellize Technology LLC, work to specific
                  “Corporate Ethical Action Guidelines”. All of our organization,including
                  its employees and any association involved in business operations commissioned
                  by our company, shall uphold a strong sense of ethics when engaging in business,
                  whilst being fully aware of, and complying with, laws and relevant information
                  pertaining to our business activities.
                </p>
                <p class="pb-5">
                  The Company is committed to being a creator of smart living
                  trusted by customers, connecting the world to innovate and
                  share a good smart living, improving the quality of products
                  and services continuously to fulfill customer needs. Future
                  products and services will be developed in a "smart" way.
                  Internet of Things, cloud computing, Big Data and other
                  technologies will be used for the smart processing on data and
                  information. Our vision is to achieve operational excellence
                  in our process and to deliver the right goods and services, to
                  the right place, at the right time, of the right quality, in
                  the most cost-effective way. Intellize suppliers help us to
                  support the needs of our growing network. We work with
                  suppliers that are chosen because of their commitment to the
                  high standards and values that are the backbone of our
                  culture.
                </p>
                <p>
                  We value the strong and long lasting alliances that we have
                  built with our suppliers and are committed to sustaining these
                  partnerships. We strive to maintain close business
                  relationships in order to promote optimal benefits for our
                  suppliers, our customers and Intellize Group.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
