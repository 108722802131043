import React, { Component } from "react";
import ContactUs from "../images/webImages/Contact.jpg";

export default class Contact extends Component {
  render() {
    return (
      <div
        className="parentContainer"
        style={{
          backgroundImage: `url(${ContactUs})`,
          overflowY: "scroll",
        }}
      >
        <div className="EmptyDiv"></div>
        <div className="mainContainer">
          <div className="childContainer">
            <h1 className="h1">Contact</h1>
            <h6 id="borderbelow" class="font-weight-bold mb-4">
              Contact Us
            </h6>
          </div>
        </div>
        <div class="py-5 layer">
          <div class="container">
            <div class="row">
              <div class="col-sm-12 py-3 text-center text-white heding">
                <h4>INTELLIZE TECHNOLOGY LLC</h4>
                <p class="">
                  # 805, BB2 Mazaya Business Avenue, Jumeirah Lakes Towers,
                  Dubai
                </p>
              </div>
              <div class="col-md-6 mb-3">
                <p class="text-center text-white">Contact us by telephone:</p>
                <div class="p-5 border border-light text-center">
                  <a
                    style={{ fontSize: "20px" }}
                    class="text-white text-center"
                    href="tel:+0421340352"
                  >
                    <small>(Tharun) 0097144214247</small>
                  </a>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <p class="text-center text-white">Contact us by e-mail:</p>
                <div class="p-5 border border-light text-center">
                  <a
                    style={{ fontSize: "20px" }}
                    class="text-white text-center"
                    href="mailto:Tharun@intellizetechnology.com"
                  >
                    <small>info@intellizetechnology.com</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
